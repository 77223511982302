import React from 'react'

export default function MFooter () {
  return (
    <div className='content--foter'>
      <button className='content--foter__button'>Buka Lelang</button>
      <p className='m-0'>FAQ | SnK | Panduan</p>
      <p className='mt-2'>
        <span className='mr-1'>&copy;</span>
        2021, Hobids, Dlingo, Yogyakarta
      </p>
    </div>
  )
}
