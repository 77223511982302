import React from 'react'
import {
  AGap,
  MBottomNavigation,
  MCard,
  MCardCategory,
  MHeader,
  MHeaderProduct,
  MSliderBanner,
  MTransacionHistory
} from 'src/components'
import {
  ICFlashSale,
  ICHotItem,
  ICRecommend,
  ILProduct1,
  ILProduct2,
  ILPromo1,
  ILPromo2,
  ILPromo3,
  ILPromo4,
  ILSaleStore
} from 'src/assets'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'
const { TabPane } = Tabs

const EcommerceHome = () => {
  const itemPromo = [ILPromo1, ILPromo2, ILPromo3, ILPromo4]
  const listItem = ['Untuk Anda', 'Kucing', 'Burung', 'Serigala', 'Ikan', 'Biawak', 'Babi', 'Anjing']

  return (
    <div className='container-home container-fluid p-0'>
      <div className='container-home__banner'>
        <MHeader ecommerce />
        <MSliderBanner />
      </div>
      <div className='container-home__cart'>
        <MTransacionHistory ecommerce />
      </div>

      <AGap height='1' />
      <div className='container-home--tab pl-3'>
        <Tabs type='card' defaultActiveKey='1'>
          {listItem.map((item, index) => (
            <TabPane tab={item} key={index + 1}>
              {item}
            </TabPane>
          ))}
        </Tabs>
      </div>

      <AGap height='1' />
      <div className='container-global__slider'>
        <MHeaderProduct title='Hot Item' icon={ICHotItem} ecommerce />
        <MCard
          hours='12'
          minutes='00'
          seconds='59'
          ecommerce
        />
      </div>

      <MHeaderProduct icon={ICFlashSale} title='Flash Sale' />

      <AGap height='.5' />
      <div className='item--timeout'>
        <p className='text m-0 mr-2'>Berakhir dalam</p>
        <p className='time m-0'>
          <ClockCircleOutlined className='mr-2' />
          9 : 10 : 20
        </p>
      </div>

      <AGap height='.3' />
      <div className='card-content--vertical__background'>
        <MCard
          type='sale-limited'
          hours='12'
          minutes='00'
          seconds='59'
          ecommerce
          images={ILProduct2}
        />
      </div>

      <AGap height='1' />
      <div className='container-global__slider'>
        <MHeaderProduct icon={ICRecommend} title='Rekomendasi' />
        <MCard
          hours='12'
          minutes='00'
          seconds='59'
          ecommerce
          images={ILProduct2}
        />
      </div>

      <div className='container-home__category'>
        <MHeaderProduct title='Kategori' without />
        <AGap height='1' />
        <MCardCategory />
      </div>

      <AGap height='.5' />
      <MHeaderProduct
        title='Rekomendasi Toko'
        type='text'
        without
        desc
      />

      <AGap height='.5' />
      <div className='container--rekomandasi--toko'>
        <img className='img--banner' src={ILSaleStore} alt='ic-store' />
        <div className='container-fluid row justify-content-center p-0 m-0 '>
          {
            [1, 2, 3, 4].map(item => (
              <div key={item} className='list--card--rekomandasi col-6 p-0'>
                <img className='img-fluid' src={ILProduct1} alt='product-rekomendasi' />
                <p className='card--description p-0 pt-2 m-0'>
                  Sea Monsters
                </p>
              </div>
            ))
          }
        </div>
      </div>

      <AGap height='1' />
      <MHeaderProduct
        title='Spesial Promo'
        type='text'
        without
        desc
      />

      <AGap height='.5' />
      <div className='container--spesial--promo'>
        {itemPromo.map(item => (
          <img
            key={item}
            className='image--promo'
            src={item}
            alt='il-promo'
          />
        ))}
      </div>
      <AGap height='6' />

      <MBottomNavigation />
      <AGap height='6' />
    </div>
  )
}

export default EcommerceHome
