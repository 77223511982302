import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { AGap, MHeader, MListItem } from 'src/components'

const ChatList = () => {
  return (
    <div className='container-list--chat container-fluid p-0'>
      <MHeader
        type='simple-icon-right'
        label='Pesan'
        styles
        icon={<SearchOutlined style={{ fontSize: '20px', color: '#BCBCBC' }} />}
      />
      <AGap height='2' />
      <MListItem />
      <AGap height='1.5' />
      <MListItem />
      <AGap height='1.5' />
      <MListItem />
      <AGap height='1.5' />
      <MListItem />
    </div>
  )
}

export default ChatList
