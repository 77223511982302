import React from 'react'
import Slider from 'react-slick'
import { ILProduct1 } from 'src/assets'

const MCardCategory = () => {
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 1
  }

  const category = [
    { title: 'Burung' },
    { title: 'Makanan' },
    { title: 'Ikan' },
    { title: 'Reptile' },
    { title: 'Serangga' },
    { title: 'Burung' },
    { title: 'Ikan' },
    { title: 'Burung' }
  ]

  return (
    <Slider {...settings}>
      {category.map(item => (
        <div key={item} className='content-category'>
          <img width='auto' height='auto' src={ILProduct1} alt={item.title + 1} />
          <p className='m-0'>{item.title}</p>
        </div>
      ))}
    </Slider>
  )
}

export default MCardCategory
