import React from 'react'
import { Button } from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'

const AButton = ({
  title,
  styles,
  type,
  block,
  icon,
  size
}) => {
  switch (type) {
    case 'secondary':
      return (
        <button className={styles}>
          {title}
          <PlusCircleFilled />
        </button>
      )
    default:
      return (
        <Button
          block={block}
          icon={icon}
          size={size}
          className={styles}
        >
          {title}
        </Button>
      )
  }
}

export default AButton
