import React from 'react'
import {
  AButton,
  AGap,
  AInput,
  MHeader
} from 'src/components'

const ValidationPendaftaran = () => {
  return (
    <div className='container--validation-pendaftaran container-fluid p-0'>
      <MHeader type='simple' label='Pendaftaran' />
      <div className='container--validation-pendaftaran__attention-top'>
        <p className='attention-top__description'>
          Anda diharuskan mengisi data diri di bawah ini
          untuk dapat meneruskan pembelian lelang ini
        </p>
      </div>
      <div className='container--validation-pendaftaran__form'>
        <AInput placeholder='Nama Sesuai KTP' />
        <AGap height='.8' />
        <AInput placeholder='Nomor Handphone WA/TG' />
        <AGap height='.8' />
        <AInput placeholder='Nomor KTP' />
        <AGap height='.8' />
        <AInput placeholder='Nama Rekening' />
        <AGap height='.8' />
        <AInput placeholder='Alamat Lengkap KTP' />
        <AGap height='.8' />
        <AInput placeholder='cikodiko22@gmail.com' disabled />
        <AGap height='4' />
        <AButton styles='button-login' title='Selanjutnya' />
      </div>
    </div>
  )
}

export default ValidationPendaftaran
