import React from 'react'
import { Input } from 'antd'
import { useHistory } from 'react-router-dom'
import { LeftOutlined, PlusOutlined } from '@ant-design/icons'
import {
  ICMessage,
  ICNotification,
  ICSearch,
  ICCartHeader
} from 'src/assets'

const MHeader = ({
  type,
  label,
  styles,
  icon,
  avatar,
  ecommerce
}) => {
  const style = `header--with-back ${styles ? 'shadow-bottom' : ''}`
  const field = ecommerce
    ? 'content--form__searching__ecommerce'
    : 'content--form__searching__fields'

  const history = useHistory()
  const handleClik = () => {
    history.goBack()
  }

  switch (type) {
    case 'simple':
      return (
        <div className={style}>
          <LeftOutlined className='header__icon-back' onClick={handleClik} />
          <div className='text-center w-100'>
            <p className='header__title m-0'>
              {label}
            </p>
          </div>
        </div>

      )
    case 'simple-icon-right':
      return (
        <div className={style}>
          <LeftOutlined className='header__icon-back' onClick={handleClik} />
          {avatar
            ? (
              <div className='w-100'>
                <img
                  src={avatar}
                  width='32'
                  height='32'
                  alt='ic-avatar'
                  className='mr-3 ml-3'
                />
                {label}
              </div>
              )
            : (
              <p className='header__title w-100 text-center m-0'>
                {label}
              </p>
              )}
          {!icon ? <PlusOutlined className='header__icon-add' /> : icon}
        </div>
      )
    default:
      return (
        <div className='banner content--form'>
          <div className='content--form__searching'>
            <Input
              className={field}
              placeholder='Beli Barang'
            />
            <img
              className='content--form__searching__icons'
              src={ICSearch}
              alt='icon-message'
            />
          </div>
          {
          ecommerce &&
            <img
              className='content--form__icons'
              src={ICCartHeader}
              alt='icon-message'
            />
          }
          <img
            className='content--form__icons'
            src={ICMessage}
            alt='icon-message'
          />
          <img
            className='content--form__icons'
            src={ICNotification}
            alt='icon-message'
          />
        </div>
      )
  }
}

export default MHeader
