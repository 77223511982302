import React from 'react'
import { ICLogo } from 'src/assets'

const Splash = () => {
  return (
    <div className='container--splash container-fluid p-0'>
      <div className='img-background' />
      <img src={ICLogo} height='106' alt='logo-splash' />
    </div>
  )
}

export default Splash
