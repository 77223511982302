import React from 'react'
import { ICHammer } from 'src/assets'

const MHeaderProduct = ({ icon, title, desc, without, ecommerce }) => {
  const sourcesImg = !icon ? ICHammer : icon
  const description = !desc ? 'Lihat semua' : desc
  const customStyling = `font-weight-bold m-0 ${!without ? 'ml-2' : ''}`
  const iconItem = !without && <img
    className={`${ecommerce ? 'icon-ecommerce' : 'icon-home'}`}
    src={sourcesImg}
    alt='icon-hammer'
                               />

  return (
    <div className='content--header'>
      <div className='d-flex align-items-center'>
        {iconItem}
        <p className={customStyling}>
          {title}
        </p>
      </div>
      <p className='m-0 text-primary'>
        <small>
          {description}
        </small>
      </p>
    </div>
  )
}

export default MHeaderProduct
