import React from 'react'
import { ICAvatar } from 'src/assets'
import { AGap } from 'src/components'

const MListItem = () => {
  return (
    <div className='list-content--chat'>
      <div className='content--chat'>
        <img className='avatar--user' src={ICAvatar} alt='ic-icon' />
        <div className='content-left--description'>
          <div className='description--top'>
            <p className='desc--store m-0'>Birdy Store</p>
            <p className='desc--time m-0'>1 menit lalu</p>
          </div>
          <AGap height='.3' />
          <div className='description--bottom'>
            <p className='desc--content-chat m-0'>Betul sekali kakak</p>
            <p className='desc--budge m-0'>1</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MListItem
