/* eslint-disable no-undef */
import React, { useState } from 'react'
import { message, Upload } from 'antd'
import { CameraFilled, LoadingOutlined } from '@ant-design/icons'

const MUploaded = ({ label }) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(false)

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const handleChange = info => {
    console.log(info)
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setImageUrl({ imageUrl })
        setLoading(false)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading
        ? <LoadingOutlined style={{ fontSize: '26px', color: '#06A2E5', marginBottom: '15px' }} />
        : <CameraFilled style={{ fontSize: '26px', color: '#06A2E5' }} />}
      <p style={{ color: '#06A2E5', marginTop: '5px' }}>
        {label}
      </p>
    </div>
  )

  const handleActionUpload = ({ name, uid }) => {
    console.log(name, uid)
  }

  return (
    <Upload
      name='avatar'
      listType='picture-card'
      className='avatar-uploader'
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      action={(file) => handleActionUpload(file)}
    >
      {
      imageUrl
        ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
        : uploadButton
    }
    </Upload>
  )
}

export default MUploaded
