import React from 'react'
import { Link } from 'react-router-dom'
import { ICLogo } from 'src/assets/Images'
import { AInput, AButton, AGap } from 'src/components'

const Login = () => {
  return (
    <div className='container--login container-fluid p-0'>
      <AGap height='1' />
      <img src={ICLogo} className='container--login__logo' alt='ic-hobids-logo' />
      <AGap height='2' />
      <div className='container--login__form px-3'>
        <h5 className='form--title'>
          Selamat Datang <br />
          Kembali
        </h5>
        <AInput placeholder='Username' />
        <AGap height='.5' />
        <AInput placeholder='Password' type='password' />
        <p className='form--forget my-4 m-0'>
          Lupa Password ?
        </p>
      </div>
      <div className='px-3'>
        <AButton styles='button-login' title='Masuk' />
        <AGap height='.8' />
        <AButton styles='with-google' title='Masuk Dengan Google' />
      </div>
      <AGap height='2' />
      <p className='container--login__routing'>
        Belum punya akun.
        <Link to='/register' className='text-primary'>
          &nbsp;Daftar
        </Link>
      </p>
    </div>
  )
}

export default Login
