import React from 'react'
import { AGap, MHeader } from 'src/components'
import { ICAvatar } from 'src/assets'

const Partisipan = () => {
  return (
    <div className='container--partisipan container-fluid p-0'>
      <MHeader
        type='simple'
        label='Partisipan'
        styles
      />
      <AGap height='2' />
      {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
        <div key={item}>
          <div className='container--partisipan--item'>
            <div className='content-item--left'>
              <img className='item-avatar' src={ICAvatar} alt='ic-avatar' />
              <div className='partisipan--description'>
                <p className='description--name m-0'>Diko Mahendra</p>
                <p className='description--price m-0'>Rp. 900.000</p>
              </div>
            </div>
            <p className='description--time m-0'>08:00</p>
          </div>
          <AGap height='1' />
        </div>
      ))}
    </div>
  )
}

export default Partisipan
