import React from 'react'
import {
  AGap,
  MCard,
  MHeaderProduct,
  MTransacionHistory,
  MSliderBanner,
  MHeader,
  MArtikel,
  MCardCategory,
  MFooter,
  MBottomNavigation
} from 'src/components'
import {
  ICArticel,
  ICSpeaker,
  ICRecommend,
  ILSaleStore,
  ILProduct1,
  ILProduct2,
  ILPromo1,
  ILPromo2,
  ILPromo3,
  ILPromo4
} from 'src/assets'

const Home = () => {
  const itemPromo = [ILPromo1, ILPromo2, ILPromo3, ILPromo4]
  return (
    <div className='container-home container-fluid p-0'>
      <div className='container-home__banner'>
        <MHeader />
        <MSliderBanner />
      </div>
      <div className='container-home__cart'>
        <MTransacionHistory />
      </div>

      <AGap height='1' />
      <div className='container-global__slider'>
        <MHeaderProduct title='Lelang Terbaru' />
        <MCard
          hours='12'
          minutes='00'
          seconds='59'
        />
      </div>

      <AGap height='1' />
      <MHeaderProduct icon={ICSpeaker} title='Item Teratas' />
      <div className='card-content--vertical__background'>
        <MCard
          type='sale-limited'
          hours='12'
          minutes='00'
          seconds='59'
          images={ILProduct2}
        />
      </div>

      <AGap height='1' />
      <div className='container-global__slider'>
        <MHeaderProduct icon={ICRecommend} title='Rekomendasi' />
        <MCard
          hours='12'
          minutes='00'
          seconds='59'
          images={ILProduct2}
        />
      </div>

      <AGap height='1' />
      <div className='container-home__category'>
        <MHeaderProduct title='Kategori' without />
        <AGap height='1' />
        <MCardCategory />
      </div>

      <AGap height='1' />
      <MHeaderProduct
        title='Rekomendasi Toko'
        type='text'
        without
        desc
      />

      <AGap height='.5' />
      <div className='container--rekomandasi--toko'>
        <img className='img--banner' src={ILSaleStore} alt='ic-store' />
        <div className='container-fluid row justify-content-center p-0 m-0 '>
          {
            [1, 2, 3, 4].map(item => (
              <div key={item} className='list--card--rekomandasi col-6 p-0'>
                <img className='img-fluid' src={ILProduct1} alt='product-rekomendasi' />
                <p className='card--description p-0 pt-2 m-0'>
                  Sea Monsters
                </p>
              </div>
            ))
          }
        </div>
      </div>

      <AGap height='1' />
      <MHeaderProduct
        title='Spesial Promo'
        type='text'
        without
        desc
      />

      <AGap height='.5' />
      <div className='container--spesial--promo'>
        {itemPromo.map(item => (
          <img
            key={item}
            className='image--promo'
            src={item}
            alt='il-promo'
          />
        ))}
      </div>
      <AGap height='1' />
      <div className='container-home__articel'>
        <MHeaderProduct icon={ICArticel} title='Artikel' />
        {[1, 2, 3, 4].map(item => (
          <MArtikel key={item} />
        ))}
      </div>

      <AGap height='2' />
      <MBottomNavigation />
      <MFooter />
      <AGap height='6' />
    </div>
  )
}

export default Home
