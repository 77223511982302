import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { AGap, MCard, MHeader } from 'src/components'

const Riwayat = () => {
  return (
    <div className='container--riwayat container-fluid p-0'>
      <MHeader
        type='simple-icon-right'
        label='Riwayat'
        styles
        icon={<InfoCircleOutlined style={{ color: '#A7A7A7' }} />}
      />
      <AGap height='1' />
      <MCard type='riwayat' title='Kucing British shorthair jantan' />
      <AGap height='1' />
      <MCard
        type='riwayat'
        title='Belalang hias jantan 1 tahun jinak'
        received
      />
      <AGap height='5' />
    </div>
  )
}
export default Riwayat
