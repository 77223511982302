import React from 'react'
import Slider from 'react-slick'
import { ILBanner } from 'src/assets'

const MSliderBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    centerMode: true,
    dotsClass: 'button__bar',
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    centerPadding: '20px'
  }

  return (
    <div className='content--slider'>
      <Slider {...settings}>
        {[1, 2, 3, 4, 5].map((item, index) => (
          <img
            key={index}
            src={ILBanner}
            alt={`images-slider${index}`}
          />
        ))}
      </Slider>
    </div>
  )
}

export default MSliderBanner
