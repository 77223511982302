import React from 'react'
import { ICStoreYellow, ICHammer } from 'src/assets'
import { AGap } from 'src/components'

const ChoiceMetode = () => {
  return (
    <div className='container--choice-metode container-fluid p-0'>
      <AGap height='10' />
      <div className='container--choice-metode__description'>
        <p className='description--title mb-2 m-0'>
          Pilih Metode Belanja
        </p>
        <p className='description--subtitle m-0'>
          Pilih salah satu metode dibawah ini
          untuk melanjutkan pembelian / perbelanjaan.
        </p>
      </div>
      <AGap height='2' />
      <div className='container--choice-metode__choice-bidding'>
        <img className='icon--bid' src={ICHammer} alt='ic-icon' />
        <div className='description'>
          <p className='description__title m-0'>Pelelangan</p>
          <p className='description__subtitle m-0'>Menangkan Item dengan Bid tertinggi</p>
        </div>
      </div>
      <AGap height='1' />
      <div className='container--choice-metode__choice-ecommerce'>
        <img className='icon--store' src={ICStoreYellow} alt='ic-icon' />
        <div className='description'>
          <p className='description__title m-0'>E-Commerce</p>
          <p className='description__subtitle m-0'>Beli item layaknya toko biasa</p>
        </div>
      </div>
    </div>
  )
}

export default ChoiceMetode
