import React from 'react'
import { ICArrowRight, ICCart, ICMyVoucher, ICMyPoint } from 'src/assets'

const MTransacionHistory = ({ ecommerce }) => {
  const ecommerceItem = [
    { title: '20.000', desc: 'Point Saya', icon: ICMyPoint },
    { title: '3 Voucher', desc: 'Voucher Tersisa', icon: ICMyVoucher }
  ]
  switch (ecommerce) {
    case true:
      return (
        <div className='card-content--payments'>
          {ecommerceItem.map((item, id) => (
            <div key={id}>
              <div className='d-flex align-items-center'>
                <div className='icon--left'>
                  <img className='icons' src={item.icon} alt='icon-cart' />
                </div>
                <div className='descriptions'>
                  <p className='m-0 text--title'>
                    {item.title}
                  </p>
                  <p className='m-0 text--subtitle'>
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )

    default:
      return (
        <div className='card-content--payments'>
          <div className='d-flex'>
            <div className='icon--left medium'>
              <img className='icons' src={ICCart} alt='icon-cart' />
            </div>
            <div className='descriptions'>
              <p className='m-0 tz-medium'>Riwayat Transaksi</p>
              <p className='m-0 tz-small tc-secondary-01'>2 dalam proses</p>
            </div>
          </div>
          <div className='icon--right'>
            <img className='icon' src={ICArrowRight} alt='icon-arrow' />
          </div>
        </div>
      )
  }
}

export default MTransacionHistory
