import React from 'react'
import { AButton, AGap, MHeader, MUploaded } from 'src/components'

const ValidationKTP = () => {
  return (
    <div className='container--validation-ktp container-fluid p-0'>
      <MHeader type='simple' label='Foto KTP' />
      <div className='container--validation-ktp__attention-top'>
        <p className='attention-top__description'>
          Anda diharuskan mengisi data diri di bawah ini
          untuk dapat meneruskan pembelian lelang ini
        </p>
      </div>
      <div className='container--validation-ktp__form'>
        <MUploaded label='Upload KTP mu' />
        <MUploaded label='Upload Selfie' />
        <AGap height='5' />
        <AButton styles='button-login' title='Selanjutnya' />
      </div>
    </div>
  )
}

export default ValidationKTP
