import React from 'react'
import { ICTimerSecondary, ILProduct3 } from 'src/assets'

export default function MArticel () {
  return (
    <div className='content-artikel'>
      <img width='auto' height='auto' alt='product-1' src={ILProduct3} />
      <div className='wrapper-description'>
        <p className='m-0'>How to make aquascape look like profesional</p>
        <p className='m-0 my-1'>aquascape fish tips</p>
        <div className='wrapper-description__timer'>
          <img width='auto' height='auto' alt='product-1' src={ICTimerSecondary} />
          <p className='m-0 ml-1'>4 hr ago</p>
        </div>
      </div>
    </div>
  )
}
