import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import {
  ChoiceMetode,
  Details,
  Home,
  Login,
  Profile,
  Register,
  Splash,
  ValidationKTP,
  ValidationPendaftaran,
  Checkout,
  MetodePengiriman,
  Alamat,
  Partisipan,
  Riwayat,
  ChatList,
  ChatBox,
  EcommerceHome
} from 'src/pages'

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/me' component={Profile} />
        <Route path='/splash' component={Splash} />
        <Route path='/details' component={Details} />
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/artikel' component={Home} />
        <Route path='/pilih-metode' component={ChoiceMetode} />
        <Route path='/validation-pendaftaran' component={ValidationPendaftaran} />
        <Route path='/validation-foto-ktp' component={ValidationKTP} />
        <Route path='/checkout' component={Checkout} />
        <Route path='/alamat' component={Alamat} />
        <Route path='/metode-pengiriman' component={MetodePengiriman} />
        <Route path='/partisipan' component={Partisipan} />
        <Route path='/riwayat' component={Riwayat} />

        <Route path='/list-chat' component={ChatList} />
        <Route path='/chat' component={ChatBox} />

        <Route path='/e' component={EcommerceHome} />
      </Switch>
    </Router>
  )
}

export { Routers }
