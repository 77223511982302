import React, { useState } from 'react'
import { Collapse, Input } from 'antd'
import Slider from 'react-slick'
import {
  ILBannerDetails1,
  ICArrowLeft,
  ICTimer,
  ICStore,
  ICWater,
  ICMessageOutlineBlue,
  ICSpeaker,
  ICLoved,
  ICButtonSend,
  ICAvatar
} from 'src/assets'

import { MCard } from 'src/components'
import { Link } from 'react-router-dom'

const { Panel } = Collapse

const Details = () => {
  const [current, setCurrent] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    dotsClass: 'button__bar',
    slidesToScroll: 1
  }

  const settinsTicket = {
    dots: false,
    focusOnSelect: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: false,
    afterChange: function (index) {
      setCurrent(index)
    }
  }

  return (
    <div className='container--details container-fluid p-0'>
      <div className='container--details__banner'>
        <div className='icon-back'>
          <img src={ICArrowLeft} alt='ic-back' />
        </div>
        <Slider {...settings} edgeFriction={0}>
          {
            [1, 2, 3, 4, 5].map(item => (
              <div key={item}>
                <img src={ILBannerDetails1} key={item} alt='item' className='img-fluid' />
              </div>
            ))
          }
        </Slider>
      </div>

      <div className='container--details__descriptions'>
        <p className='container--details__descriptions--title'>
          Kucing british short hair jantan
        </p>

        <div className='details--bid'>
          <div className='details--bid__content-top'>
            <p className='m-0 mb-1'>Bid Terakhir</p>
            <p className='m-0 mb-1 mr-4 pr-3'>Sisa waktu</p>
          </div>
          <div className='details--bid__content-bottom'>
            <p className='m-0'>Rp 1.000.000</p>
            <div className='d-flex'>
              <img src={ICTimer} alt='icon-store' />
              <p className='text--danger light ml-2 m-0'>
                00 : 10 : 20
              </p>
            </div>
          </div>
        </div>

        <div className='actions--bids'>
          <button className='text-white'>Beli Sekarang</button>
          <p className='m-0'>Rp 2.000.000</p>
        </div>
      </div>

      <div className='container--details__store'>
        <div className='d-flex'>
          <div className='store-content__left'>
            <img src={ICStore} alt='ic-store' height='19' />
          </div>
          <div className='store-content__center'>
            <p className='description-top m-0'>The Catten Store</p>
            <p className='description-bottom m-0'>Jakarta Pusat</p>
          </div>
        </div>
        <div className='store-content__right'>
          <img src={ICWater} alt='icon-ege' height='15' />
          <p className='m-0 ml-1'>500</p>
        </div>
      </div>

      <div className='container--details__description-product'>
        <p className='description-top m-0'>Deskripsi</p>
        <p className='description-center m-0'>
          Kucing British Shorthair umur 1 tahun
          jantan menggemaskan terlatih buang kotorang
          di WC atau pun bisa juga di pasir. Jinak tidak suka menggig...
        </p>
        <p className='description-bottom'>Selengkapnya</p>
      </div>

      <div className='container--details__header-bidding'>
        <p className='description-left'>Bid Terakhir</p>
        <p className='description-right'>
          <Link to='/partisipan'>20 partisipan</Link>
        </p>
      </div>

      <Slider
        className='container--details__slider-live-streaming'
        {...settinsTicket}
      >
        {
            [1, 2, 3, 4, 5].map(item => (
              <div key={item} className='user--top d-flex'>
                <img className='user--top__avatar' src={ICAvatar} alt='ic-icon' />
                {current !== item && (
                  <div className='user--top__description'>
                    <p className='description-top m-0'>Jane Cooper {item}</p>
                    <p className='description-bottom m-0'>Rp 1.000.000</p>
                  </div>
                )}
              </div>
            ))
          }
      </Slider>

      <div className='container--details__card-recommandation'>
        <p className='title--recommendation m-0'>
          Item yang mungkin anda suka
        </p>
        <MCard />
      </div>

      <Collapse
        className='container--details__live-chat'
        expandIconPosition='right'
        defaultActiveKey={['1']}
      >
        <Panel
          header={[
            <div key='1' className='d-flex align-items-center'>
              <div className='dot--symbols' />
              <p className='font-weight-bold m-0'>Live Chat &nbsp;
                <span className='text-secondary font-weight-normal'>
                  (19 orang)
                </span>
              </p>
            </div>
          ]}
          key='1'
        >
          <div className='live-chat__opened'>
            <img src={ICSpeaker} height='24' alt='ic-speaker' />
            <p className='m-0 ml-3'>
              Live Chat ini bertujuan untuk memudahkan pembeli
              saling berbagi informasi atau berkomunikasi. Namun
              tetap menjaga tata krama dan bahasa. Terimakasih
            </p>
          </div>
          {
            [1, 2, 3].map(index => (
              <div key={index} className='content-bidding--product d-flex align-items-center'>
                <img src={ICAvatar} height='32' alt='ic-avatar' />
                <p className='m-0 font-weight-bold ml-3'>
                  Feri sa : <span className='font-weight-light text-secondary'>Up gan!</span>
                </p>
              </div>
            ))
          }
          <div className='content-form--sending'>
            <img src={ICLoved} alt='ic-loved' height='17' width='20' />
            <img src={ICButtonSend} alt='ic-send' onClick={() => {}} />
            <Input placeholder='Tulis sesuatu' />
          </div>
        </Panel>
      </Collapse>

      <div className='container--details__bidding-actions px-4'>
        <div className='d-flex'>
          <div className='content--left mr-3'>
            <img src={ICMessageOutlineBlue} alt='ic-msg' height='13' />
          </div>
          <button className='button bid'>
            Lompat Bid
          </button>
        </div>
        <button className='button bid-price'>Bid +Rp 100.000</button>
      </div>
    </div>
  )
}

export default Details
