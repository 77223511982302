import React from 'react'
import { Input } from 'antd'
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined
} from '@ant-design/icons'

const AInput = ({
  placeholder,
  type,
  size,
  disabled
}) => {
  switch (type) {
    case 'password':
      return (
        <Input.Password
          placeholder={placeholder}
          className='form--auth rounded-pill pr-3'
          size={size || 'large'}
          prefix={<LockOutlined className='pl-2 pr-1' />}
          iconRender={visible => (
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          )}
        />
      )
    case 'simple':
      return (
        <Input
          placeholder={placeholder}
          disabled={disabled}
          size={size || 'large'}
          className='simple'
        />
      )

    default:
      return (
        <Input
          placeholder={placeholder}
          size={size || 'large'}
          disabled={disabled}
          className='form--auth rounded-pill'
          prefix={<MailOutlined className='pl-2 pr-1' />}
        />
      )
  }
}

export default AInput
