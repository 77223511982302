import React from 'react'
import { Input } from 'antd'
import { AGap, MHeader } from 'src/components'
import { ICAvatar2, ICButtonSend, ICRead, ILProduct1 } from 'src/assets'
import { MoreOutlined, PictureOutlined } from '@ant-design/icons'

const ChatBox = () => {
  return (
    <div className='container--chat container-fluid p-0'>
      <MHeader
        type='simple-icon-right'
        icon={<MoreOutlined style={{ fontSize: '22px', color: 'grey' }} />}
        label='Birdy Store'
        styles
        avatar={ICAvatar2}
      />
      <div className='container--content--chat'>
        <div className='content--tagged--store'>
          <img className='store--image' src={ILProduct1} alt='ic-product-tagged' />
          <div className='content--tagged--description'>
            <p className='description--title m-0'>
              Kucing British short hair jantan...
            </p>
            <span className='description--price'>
              Rp 1.000.000
            </span>
          </div>
        </div>
        <div className='content--chat--attention'>
          <p className='m-0'>
            Belum ada pesan saat ini. Mohon tidak bertransaksi
            di luar Hobids. Gunakanlah bahasa yang
            sopan untuk kenyamanan sesama pengguna Hobids
          </p>
        </div>
        <AGap height='2' />
        {
          [1, 2, 3, 4, 5, 6, 7].map(item => (
            <div key={item}>
              <div className='content--chat--others'>
                <p className='m-0'>
                  Semua burung di toko kami
                  dalam kondisi sehat semua pak
                </p>
                <span className='chat--time'>11: 20</span>
              </div>

              <AGap height='1' />

              <div className='d-flex justify-content-end'>
                <div className='content--chat--me'>
                  <p className='m-0'>
                    Ok baiklah terimkasih
                  </p>
                  <span className='chat--time'>11: 20</span>
                  <img className='icon--read' src={ICRead} alt='ic-read-2' />
                </div>
              </div>
              <AGap height='1' />
            </div>
          ))
        }
        <AGap height='5' />
        <div className='content-form--sending fixed'>
          <PictureOutlined style={{ fontSize: '22px', color: 'grey' }} />
          <img src={ICButtonSend} alt='ic-send' onClick={() => {}} />
          <Input placeholder='Tulis sesuatu' />
        </div>
      </div>
    </div>
  )
}

export default ChatBox
