import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { CheckOutlined, RightOutlined } from '@ant-design/icons'
import { AGap, AButton } from 'src/components'
import {
  ICStore,
  ICTimer,
  ICWater,
  ILProduct1,
  ILSaleLimited
} from 'src/assets'

const MCard = ({
  images,
  hours,
  minutes,
  seconds,
  type,
  icon,
  label,
  title,
  subtitle,
  description,
  received,
  selected,
  ecommerce
}) => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  }

  const sourceImgTop = !images ? ILProduct1 : images

  const flash = (key) => type === 'sale-limited' && key === 1
    ? !ecommerce && <img
        className='image--sale--limited'
        src={ILSaleLimited}
        key={key}
        alt='ic-limited'
                    />
    : (
      <Link to='/details' key={key} className='card-content--vertical'>
        <img className='card-img-top' src={sourceImgTop} alt='image-card' />

        <div className='card-content--vertical__detail-description'>

          <p className='text--title m-0'>
            Ikan Mas pejantan oranye putih
          </p>
          <p className='text--subtitle mt-2 m-0'>
            Bid terakhir
          </p>
          <p className='text--danger mt-1 m-0'>
            Rp 1.000.000
          </p>
          <p className='text--subtitle my-1 m-0'>
            Kelipatan bid
          </p>
          <p className='text--danger light m-0'>
            Rp 100.000
          </p>

          <div className='__description-store mt-2'>
            <img src={ICStore} alt='icon-store' />
            <p className='text--secondary light ml-2 m-0'>
              Fish Buddy Store
            </p>
          </div>

          <div className='__description-store mt-1'>
            <img src={ICWater} alt='icon-store' />
            <p className='text--secondary light ml-2 m-0'>
              500 | Bandung
            </p>
          </div>

          {!ecommerce && (
            <>
              <p className='text--secondary sm mt-2 m-0'>
                Waktu Tersisa
              </p>
              <div className='__description-store __description-timer'>
                <img src={ICTimer} alt='icon-store' />
                <p className='text--danger light ml-2 m-0'>
                  {hours} : {minutes} : {seconds}
                </p>
              </div>
            </>
          )}
        </div>
      </Link>
      )

  switch (type) {
    case 'simple':
      return (
        <div className='card-content--horizontal'>
          <p className='card-content--horizontal--label m-0'>
            {label}
          </p>
          <div className='card-content--horizontal--card'>
            <div className='content--left'>
              <div className='content--left__icon'>
                {icon}
              </div>
              <div className='content--left__description'>
                <p className='description--title m-0 pr-3'>
                  {title}
                </p>
                <span className='description--subtitle'>
                  {subtitle}
                </span>
              </div>
            </div>
            <RightOutlined />
          </div>
        </div>
      )
    case 'alamat':
      return (
        <div className='card-content--alamat'>
          <div className='content-card--left'>
            <AGap height='.1' />
            <p className='description--title m-0'>
              {title}
            </p>
            <AGap height='.5' />
            <p className='description--phone m-0'>
              +628822738920
            </p>
            <AGap height='.5' />
            <div className='description--address w-100 m-0'>
              {description}
            </div>
            <AGap height='1' />
            <AButton
              title='Ubah Alamat'
              styles={`change--addresses rounded-pill ${!selected && 'border--primary'}`}
            />
          </div>
          {
            selected && (
              <div className='content-card--right w-100'>
                <CheckOutlined style={{ color: '#06A2E5', textAlign: 'center' }} />
              </div>
            )
          }
        </div>
      )
    case 'ekspedisi':
      return (
        <div className='card-content--ekspedisi'>
          <p className='title--type m-0'>{label}</p>
          {
            [1, 2].map(item => (
              <div key={item}>
                <AGap height='1' />
                <div className='card-metode--pembayaran'>
                  <div>
                    <p className='title--ekspedisi m-0'>{title}</p>
                    <AGap height='.2' />
                    <p className='title--estimasi m-0'>{description}</p>
                  </div>
                  <CheckOutlined style={{ color: '#06A2E5' }} />
                </div>
              </div>
            ))
          }
        </div>
      )
    case 'riwayat':
      return (
        <div className='container-card--riwayat'>
          <div className='card-content--top'>
            <img className='card-content--avatar' src={ILProduct1} alt='ic-icon-avatar' />
            <div className='content-top--description'>
              <p className='description--product m-0'>{title}</p>
              <AGap height='.3' />
              <p className='description--proccess m-0'>
                {received ? 'Telah Diterima' : 'Sedang Dikirm'}
              </p>
            </div>
          </div>
          <hr />
          <div className='card-content--bottom'>
            <div className='content-bottom--description-item'>
              <p className='tc-secondary-01'>Harga Item</p>
              <p className='tc-black-01'>Rp 2.000.000,-</p>
            </div>
            <div className='content-bottom--description-item'>
              <p className='tc-secondary-01'>Harga Total</p>
              <p className='tc-danger-01 tw-normal'>Rp 2.020.000,-</p>
            </div>
            <div className='content-bottom--description-item'>
              <p className='tc-secondary-01'>Toko</p>
              <p className='tc-black-01'>Catten Store</p>
            </div>
            <div className='content-bottom--description-item'>
              <p className='tc-secondary-01'>Tanggal Pembelian</p>
              <p className='tc-black-01'>20 Januari 2021</p>
            </div>
            <AGap height='1' />
            {
              !received
                ? (
                  <AButton
                    title='Lacak'
                    styles='rounded-pill button-success'
                    block
                  />
                  )
                : (
                  <div className='row justify-content-between px-2'>
                    <AButton
                      title='Selesai'
                      styles='rounded-pill button-success--01'
                    />
                    <AButton
                      title='Komplain'
                      styles='rounded-pill button-outlined--01'
                    />
                  </div>
                  )
            }
          </div>
        </div>
      )
    default:
      return (
        <Slider {...settings}>
          {[1, 2, 4, 5, 6, 7].map(item => (
            flash(item)
          ))}
        </Slider>
      )
  }
}

export default MCard
