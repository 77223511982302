import React from 'react'
import { Link } from 'react-router-dom'
import { ICLogo } from 'src/assets/Images'
import { AButton, AGap, AInput } from 'src/components'

const Register = () => {
  return (
    <div className='container--register container-fluid px-0'>
      <AGap height='1.2' />
      <img
        src={ICLogo}
        className='container--register__logo'
        alt='ic-hobids-logo'
      />
      <AGap height='1' />
      <div className='container--register__form px-3 mt-3'>
        <p className='form--title m-0'>Selamat Datang</p>
        <p className='form--subtitle m-0'>
          Daftar Untuk Melanjutkan
        </p>
        <AGap height='1' />
        <AInput placeholder='Username' />
        <AGap height='.8' />
        <AInput placeholder='First Name' />
        <AGap height='.8' />
        <AInput placeholder='Last Name' />
        <AGap height='.8' />
        <AInput placeholder='Nomer Handphone' />
        <AGap height='.8' />
        <AInput placeholder='E-mail' />
        <AGap height='.8' />
        <AInput placeholder='Password' type='password' />
        <AGap height='.8' />
        <AInput placeholder='Konfirmasi Password' type='password' />
        <p className='forget--password my-3 m-0'>
          Lupa Password ?
        </p>
      </div>
      <div className='px-3'>
        <AButton styles='button-login' title='Daftar' />
        <AGap height='.8' />
        <AButton styles='with-google' title='Daftar Dengan Google' />
      </div>
      <AGap height='1' />
      <p className='container--register__routing'>
        Sudah punya akun.
        <Link to='/login' className='text-primary'>
          &nbsp;Masuk
        </Link>
      </p>
      <AGap height='.8' />
    </div>
  )
}

export default Register
