import React from 'react'
import { AGap, MHeader, MCard, AButton } from 'src/components'

const Alamat = () => {
  return (
    <div className='container-detail--alamat container-fluid p-0'>
      <MHeader
        type='simple-icon-right'
        label='Alamat'
        styles='shadow-bottom'
      />
      <AGap height='1' />
      <MCard
        type='alamat'
        selected
        title='Yuri Gagarin'
        description='RT 02 RW 03 Sukorame, Dlingo, Yogyakarta'
      />
      <AGap height='1' />
      <MCard
        type='alamat'
        title='Yuri Gagarin'
        description='Depan Masjid Putih, Rt 10 Rw 03, Mangunan Yogyakarta'
      />
      <AGap height='10' />
      <div className='button--action'>
        <AButton title='Pilih' styles='button-login' />
      </div>
    </div>
  )
}

export default Alamat
