import React from 'react'
import { MBottomNavigation } from 'src/components'

const Profile = () => {
  return (
    <MBottomNavigation />
  )
}

export default Profile
