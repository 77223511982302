import React from 'react'
import ReactDOM from 'react-dom'
import { Routers } from './router'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import reducers from './redux/reducers'
import sagas from './redux/sagas'

import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'

import './assets/Styles/global.scss'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// import serviceDevelopment from './serviceDevelopments'

require('dotenv').config()

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
  middlewares.push(logger)
}

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Routers history={history} />
  </Provider>
  ,
  document.getElementById('root')
)

export { store, history }

// serviceDevelopment()
