import React from 'react'
import { ILProduct1 } from 'src/assets'
import {
  AButton,
  AGap,
  AInput,
  MCard,
  MHeader
} from 'src/components'
import {
  CarOutlined,
  CreditCardOutlined,
  EnvironmentOutlined
} from '@ant-design/icons'

const styleIconColor = { fontSize: '22px', color: '#06A2E5' }

const Checkout = () => {
  return (
    <div className='container--details-checkout container-fluid p-0'>
      <MHeader type='simple' label='Checkout' styles='shadow-bottom' />
      <AGap height='1' />
      <div className='checkout--item'>
        <p className='checkout--item--label m-0'>
          Item
        </p>
        <div className='checkout--item--card'>
          <img className='checkout--item--image' src={ILProduct1} alt='icon-banner' />
          <div className='checkout--item--card__description'>
            <p className='description--title m-0'>
              Kucing British Shorthair jantan
            </p>
            <p className='description--price m-0 mt-1'>
              Rp 2.000.000,-
            </p>
          </div>
        </div>
      </div>
      <AGap height='1' />
      <MCard
        type='simple'
        label='Alamat'
        title='RT 02 RW 03 Sukorame, Dlingo, Yogyakarta'
        icon={<EnvironmentOutlined style={styleIconColor} />}
      />
      <AGap height='1.5' />
      <MCard
        type='simple'
        label='Metode Pembayaran'
        title='02099393032'
        subtitle='Bank Syariah Indonesia'
        icon={<CreditCardOutlined style={styleIconColor} />}
      />
      <AGap height='1.5' />
      <MCard
        type='simple'
        label='Metode Pengiriman'
        title='Garuda Travel'
        subtitle='Reguler'
        icon={<CarOutlined style={styleIconColor} />}
      />
      <AGap height='1.5' />
      <div className='checkout--karantina'>
        <p className='m-0'>Masa Karantina</p>
        <AGap height='.5' />
        <AInput type='simple' placeholder='12 Hari' />
        <AGap height='.8' />
        <AButton
          title='Tambahkan Catatan'
          type='secondary'
          styles='secondary-disabled'
        />
        <AGap height='2' />
        <AButton
          title='Selanjutnya'
          styles='button-login'
        />
        <AGap height='3' />
      </div>
    </div>
  )
}

export default Checkout
