import React from 'react'
import { AButton, AGap, MCard, MHeader } from 'src/components'

const MetodePengiriman = () => {
  return (
    <div className='container--metode-pembayaran container-fluid p-0'>
      <MHeader type='simple' styles='shadow-bottom' label='Metode Pengiriman' />
      <AGap height='1' />
      <div className='content-metode--pembayaran'>
        <MCard
          label='Regular'
          type='ekspedisi'
          title='Rosalia Travel'
          description='Estimasi tiba 8 - 10 Juli'
        />
        <hr />
        <MCard
          label='Ekspress'
          type='ekspedisi'
          title='J & T Express'
          description='Estimasi tiba 7 - 8 Juli'
        />
        <hr />
        <MCard
          type='ekspedisi'
          label='Instant'
          title='GoJek'
          description='Estimasi tiba hari ini'
        />
        <AGap height='10' />
        <AButton styles='button-login' title='Pilih' />
      </div>
    </div>
  )
}

export default MetodePengiriman
