import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  ICTNavigationAccount,
  ICTNavigationArtikel,
  ICTNavigationHome,
  ICTNavigationBid
} from 'src/assets'

const MBottomNavigation = (context) => {
  const navigation = [
    { to: '/', text: 'Beranda', icons: ICTNavigationHome },
    { to: '/bid', text: 'Bid', icons: ICTNavigationBid },
    { to: '/artikel', text: 'Artikel', icons: ICTNavigationArtikel },
    { to: '/me', text: 'Akun', icons: ICTNavigationAccount }
  ]

  return (
    <div className='bottom--navigation'>
      {navigation.map(item => (
        <div key={item.text} className='navbar-nav navigation--item'>
          <Link to={item.to}>
            <img width='auto' height='auto' src={item.icons} alt={item.text} />
            <p className='m-0'>{item.text}</p>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default connect()(MBottomNavigation)
